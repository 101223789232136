<template>
  <div class="process-full absolute fullscreen q-pa-lg" v-if="files.length">
    <div class="process-full__content">
      <q-linear-progress indeterminate stripe size="10px" rounded color="secondary" />
      <q-list separator dense class="q-mt-sm q-list--no-padding text-white">
        <q-item v-for="file in files" :key="file.name">
          <q-item-section class="text-subtitle1 text-bold">
            <q-item-label class="full-width ellipsis">
              {{ file.name }}
            </q-item-label>
            <q-item-label caption class="text-white">
              {{ formatBytes(file.size) }}
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </div>
  </div>
</template>

<script lang="ts">
import { formatBytes } from '@/utils/helpers'
import { Vue, Options } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

@Options({
  components: {},
})
export default class UploadingProgress extends Vue {
  @Prop({ default: [] })
  files!: File[]

  formatBytes = formatBytes
  progressVal = 0.1

  @Watch('files')
  filesChanged() {
    if (!this.files.length) {
      this.progressVal = 0.1
      return
    }

    setTimeout(() => {
      this.progressVal = 0.95
    }, 1000)
  }
}
</script>
<style lang="scss" scoped>
.process-full {
  background-color: rgba(0, 0, 0, 0.7);
  .process-full__content {
    margin-top: 20px;
  }
}
</style>
