import { render } from "./UploadingProgress.vue?vue&type=template&id=1d7abd28&scoped=true"
import script from "./UploadingProgress.vue?vue&type=script&lang=ts"
export * from "./UploadingProgress.vue?vue&type=script&lang=ts"

import "./UploadingProgress.vue?vue&type=style&index=0&id=1d7abd28&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-1d7abd28"

export default script
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QLinearProgress,QList,QItem,QItemSection,QItemLabel});
