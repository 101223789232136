
import { formatBytes } from '@/utils/helpers'
import { Vue, Options } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

@Options({
  components: {},
})
export default class UploadingProgress extends Vue {
  @Prop({ default: [] })
  files!: File[]

  formatBytes = formatBytes
  progressVal = 0.1

  @Watch('files')
  filesChanged() {
    if (!this.files.length) {
      this.progressVal = 0.1
      return
    }

    setTimeout(() => {
      this.progressVal = 0.95
    }, 1000)
  }
}
