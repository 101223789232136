import gql from 'graphql-tag'

const baseReturn = `
  filename
  mimetype
  encoding
  url
`

const FILE_UPLOAD_GQL = {
  UPLOAD_SINGLE_MUTATION: gql`
    mutation upload_single($input: FileUpload!) {
      upload_single(input: $input) {
        ${baseReturn}
      }
    }
  `,
}

export default FILE_UPLOAD_GQL
