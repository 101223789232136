import { IFileUploadResult } from '@/utils/types'
import { NormApi } from '../../api/norm'
import FILE_UPLOAD_GQL from './upload-graphql'

export class UploadApi extends NormApi<IFileUploadResult> {
  uploadFile = async (file: File): Promise<IFileUploadResult | null> => {
    return this.mutation({
      query: FILE_UPLOAD_GQL.UPLOAD_SINGLE_MUTATION,
      variables: {
        input: { file },
      },
    })
  }
}

export default new UploadApi()
