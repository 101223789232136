
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { ProjectModel } from '@/components/project/project-model'
import { ICustomField } from '@/components/custom-field/custom-field-model'
import cloneDeep from 'lodash/cloneDeep'
import { CustomFieldValue } from '@/utils/types'
import { customFieldDirectives } from './directives'

@Options({
  components: {},
  directives: {
    maska,
    ...customFieldDirectives,
  },
  emits: ['update:modelValue', 'update:change'],
})
export default class CustomFieldFormMixin extends Vue {
  @Prop({ default: {} })
  project!: ProjectModel

  @Prop({ default: false })
  readonly!: boolean

  @Prop()
  customField!: ICustomField

  @Prop({ default: '' })
  modelValue!: CustomFieldValue

  @Prop({ default: true })
  showLabel!: boolean

  @Prop({ default: false })
  isNew!: boolean

  isEditting = false
  orginalValue: CustomFieldValue = ''

  get value(): CustomFieldValue {
    return this.modelValue ?? (this.customField.defaultValue as string)
  }

  set value(value: CustomFieldValue) {
    this.$emit('update:modelValue', value)
    this.onChange(value)
  }

  get placeholder(): string | undefined {
    return this.customField.placeholder
  }

  setEditting() {
    this.isEditting = true
    this.orginalValue = cloneDeep(this.value)
  }

  cancelEditing(reset?: boolean) {
    this.isEditting = false
    if (reset) {
      this.value = this.orginalValue
    }
  }

  onChange(value?: CustomFieldValue) {
    this.$emit('update:change', { field: this.customField, value: value ?? this.value })
    this.isEditting = false
  }

  get isShowInput() {
    const isShow = this.isNew || !this.value || this.isEditting
    if (isShow) {
      this.isEditting = true
    }

    return isShow
  }
}
