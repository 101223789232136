const fieldHint = {
  mounted(el, binding) {
    if (binding.value) {
      const element = el as HTMLElement
      element.insertAdjacentHTML('afterend', `<span class="hint-label text-italic">${binding.value}</span>`)
    }
  },
}

const fieldRequired = {
  mounted(el, binding) {
    const element = el as HTMLElement
    if (binding.value) {
      element.classList.add('required-label')
    } else {
      element.classList.remove('required-label')
    }
  },
}

export const customFieldDirectives = {
  fieldHint,
  fieldRequired,
}
